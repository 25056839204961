import React, { useState } from "react"
import { compose, withHandlers } from "recompose"
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps"

const Map = compose(
  withHandlers(props => {
    const refs = {
      allCenter: undefined,
      allZoom: undefined,
      map: undefined,
    }
    const getBounds = () => {
      const bounds = new window.google.maps.LatLngBounds()

      if (props.markerPosition.length === 1) {
        const { lat, lon } = props.markerPosition[0]

        const expandLatBounds = 0.1
        const expandLonBounds = 0.1

        bounds.extend(
          new window.google.maps.LatLng(
            lat - expandLatBounds,
            lon - expandLonBounds
          )
        )
        bounds.extend(
          new window.google.maps.LatLng(
            lat + expandLatBounds,
            lon + expandLonBounds
          )
        )
      } else {
        props.markerPosition.forEach(marker => {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lon))
        })
      }

      return bounds
    }

    return {
      getZoomAndCenter: () => () => {
        if (!refs.allCenter && refs.map) {
          refs.allZoom = refs.map.getZoom()
          refs.allCenter = refs.map.getCenter()
        }
        return { allCenter: refs.allCenter, allZoom: refs.allZoom }
      },
      onMapMounted: () => ref => {
        if (!ref) return
        refs.map = ref
        refs.map.fitBounds(getBounds())
      },
      onZoomChanged: () => () => {
        props.zoomChange(refs.map.getZoom())
      },
    }
  }),
  withScriptjs,
  withGoogleMap
)(({ isMarkerShown, mapCenter, mapZoom, markerPosition, ...props }) => {
  const [showInfo, setShowInfo] = useState(false)
  const handleInfoWindow = (index, show) => {
    markerPosition[index].showInfo = show
    setShowInfo(show)
  }
  const { allCenter, allZoom } = props.getZoomAndCenter()

  return (
    <GoogleMap
      center={mapCenter || allCenter}
      onZoomChanged={props.onZoomChanged}
      ref={props.onMapMounted}
      zoom={mapZoom || allZoom}
    >
      {isMarkerShown &&
        markerPosition.map((item, index) => (
          <div key={index}>
            <Marker
              icon="/images/cortica-pin.png"
              onClick={() => props.handleMarkerClick(index)}
              onMouseOut={() => handleInfoWindow(index, false)}
              onMouseOver={() => handleInfoWindow(index, true)}
              position={{ lat: item.lat, lng: item.lon }}
            >
              {item.showInfo && showInfo && (
                <InfoWindow>
                  <h4 className="infoWindow mb-0 ml-1">{item.info}</h4>
                </InfoWindow>
              )}
            </Marker>
          </div>
        ))}
    </GoogleMap>
  )
})

export default Map
